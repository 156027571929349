import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { SuperstructureBaseComponent } from 'src/app/base/components/superstructure-base.component';
import { AppidService } from 'src/app/base/services/appid/appid.service';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { ContentService } from 'src/app/base/services/content/content.service';
import { I3VService } from 'src/app/base/services/i3v/i3v.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { WorkflowService } from 'src/app/base/services/workflow/workflow.service';
import { ButtonService } from 'src/app/base/services/buttons/buttons.service';
import { Location } from '@angular/common';
import { ThemeService } from 'src/app/base/services/theme/EZScssTheme.service';

@Component({
    selector: 'app-superstructure',
    templateUrl: './superstructure.component.html',
    styleUrls: ['./superstructure.component.css']
})
export class SuperstructureComponent extends SuperstructureBaseComponent implements OnInit {
    constructor(
        public route: ActivatedRoute,
        public content: ContentService,
        public router: Router,
        public sanitizer: DomSanitizer,
        public dialog: MatDialog,
        public location: Location,
        public cdr: ChangeDetectorRef,
        public titleService: Title,
        public auth: AuthService,
        public nav: NavService,
        public i3v: I3VService,
        public workflow: WorkflowService,
        public app: AppComponent,
        public AppId: AppidService,
        public http: HttpClient,
        public buttonService: ButtonService,
        public theme: ThemeService) {
        super(route, content, router, sanitizer, dialog, location, cdr, titleService, auth, nav, i3v, workflow, app, AppId, http, theme, buttonService);
    }

    generateButtons(buttons): boolean {
        return this.buttonService.generateButtons(buttons)
    }
}
