import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AppidService {

    public appID: number;
    public roles = null;

    constructor(private route: Router, private http: HttpClient) {
        this.initializeAppData();
    }

    //this function is a workaround to fix the async nature of the code to set the appid and roles properly. 
    //if we implement this directly in the constructor, app id will be undefined when updateNavs() function is invoked.
    async initializeAppData() {
        this.appID = await this.getAppID();
        this.roles = this.getRoles(this.appID);
    }

    //this function is to fetch the app label and app id from the v2 backend and set the app id by finding if the app label is a part of the url.
    getAppID(): Promise<any> {
        const url = window.location.hostname;
        return this.http.post<any>(environment.urls.core + "/auth/getappid", {url: url}).toPromise().then((app) => {
            if(app !== null){
                return app['ID'];
            }else{   
                // Default or fallback appID if no match
                //this is for local development. Change it according to your requirement.
                return url.includes('frontend--') ? 795418 : 434693;
            }
        });
    }

    getRoles(appID) {
        if (appID == 434693) {
            return {
                '97': 'Venue',
                '98': 'Performer'
            };
        } else if (appID == 795418) {
            return {
                '269': 'Staff',
                '270': 'Parent'
            };
        } else return null;
    }
}
