import { ChangeDetectorRef, Component, OnInit, ViewChild, Input } from '@angular/core';
import { SidenavComponent } from 'src/app/gigmonster/components/sidenav/sidenav.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NavService } from '../services/nav/nav.service';
import { AuthService } from '../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { Route } from '@angular/router';
import { consoleSandbox } from '@sentry/utils';

@Component({
    selector: 'app-top-nav-base',
    template: ``,
    styles: []
})
export class TopNavBaseComponent implements OnInit {
    data: Route[] = [];

    @Input("isRegMode") isRegMode: boolean = false;
    @Input("regRole") regRole: string = null;

    showMobileNav = false;

    constructor(
        public sideNav: SidenavComponent,
        public http: HttpClient,
        public router: Router,
        public navService: NavService,
        public authService: AuthService,
        public activated: ActivatedRoute,
        public app: AppComponent) { }


    ngOnInit(): void {
        //get routs from angular router
        this.data = [];
        //filter for all root routes
        this.router.config.filter(route => route.path.split("/").length == 1 && route.data != null).forEach(route => {
            let rootPath = route.path.split("/")[0];

            //recursively find first descendant route where data.hasPage is true
            let firstDescendant = this.router.config.find(r => r.path.split("/")[0] == rootPath && route.path != r.path && r.data != null && r.data.hasPage);
            let index = 1;
            while (firstDescendant != null && firstDescendant.data.hasPage == false) {
                firstDescendant = this.router.config.find(r => {
                    let baseMatch = r.path.split("/")[0] == rootPath && route.path != r.path && r.data != null && r.data.hasPage;
                    return baseMatch && r.path.split("/")[index] == firstDescendant.path.split("/")[index++];
                });
            }

            let routeCopy = { ...route };

            if (firstDescendant == null) {
                console.error("No descendant route found for " + route.path + ", does it have a page attached to it in RADMIN?");
            }

            //routeCopy.path = firstDescendant.path;
            this.data.push(routeCopy);
        });
        this.sortNavs();
    }

    sortNavs() {
        this.data.sort((a, b) => {
            if (a.data.sortOrder < b.data.sortOrder) {
                return -1;
            }
            if (a.data.sortOrder > b.data.sortOrder) {
                return 1;
            }
            return 0;
        });
    }


    logout(): void {
        let role = this.authService.getRole();
        this.navService.clearNavs().subscribe(
            () => { // success callback
                this.authService.logout();

                //if role is not set, use regRole
                if (!role) {
                    switch (this.regRole) {
                        case "venue":
                            role = 97;
                            break;
                        case "performer":
                            role = 98;
                            break;
                    }
                }
                //adding role in the query params is causing unnecessary redirection and loggin out the user. Anyway this code should be in a common place. The same code is there in 5 files.  
                this.router.navigateByUrl("/auth/login");
            },
            (error) => {
                console.error("Error clearing the navs during logout:", error);
            }
        );
    }


    navigate(link: string): void {
        //navigate and clear all query params
        //get all query params and set to null

        let queryParams = this.router.parseUrl(this.router.url).queryParams;
        for (let key in queryParams) {
            queryParams[key] = null;
        }
        //todo: needs testing with GM
        this.router.navigate(link.split("/"), {
            queryParams: queryParams,
            queryParamsHandling: "merge"
        })
    }

    //determine if this top nav is active
    isActive(link): boolean {
        return this.router.url.split("/")[1] == link.split("/")[0];
    }

    triggerMobileNav(): void {
        this.showMobileNav = true;
    }

    collapseMobileNav(): void {
        this.showMobileNav = false;
    }
}
