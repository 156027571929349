import { Component, OnInit } from '@angular/core';
import { UploadBaseComponent } from 'src/app/base/components/fields/upload-base.component';
import { ContentService } from 'src/app/base/services/content/content.service';

@Component({
  selector: '[app-easysuite-upload]',
  templateUrl: './easysuite-upload.component.html',
  styleUrls: ['./easysuite-upload.component.css']
})
export class UploadComponent extends UploadBaseComponent implements  OnInit {

  constructor(public content: ContentService) {super(content)}

  ngOnInit(): void {
  }

}
