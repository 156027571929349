import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from 'src/app/base/interfaces/field';

@Component({
  selector: 'app-text-dollars',
  templateUrl: './text-dollars.component.html',
  styleUrls: ['./text-dollars.component.css']
})
export class TextDollarsComponent {
    @Input() field: Field;
    @Input() fieldKey: string;
    @Input() formGroup: UntypedFormGroup

    @Output() blurEvent = new EventEmitter();

  constructor() { }

  // Here emit the input value
  onBlurEvent(event: FocusEvent) {
    const value = (event.target as HTMLInputElement).value;
    this.blurEvent.emit(value);
  }

}
