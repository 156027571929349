<div class="main"
    [class.invalid]="!this.formGroup.controls[this.fieldKey].valid && this.formGroup.controls[this.fieldKey].touched">
    <p style="margin-right: 10px; margin-bottom: 0px;">{{field.label}}:{{this.field.required ? " *": ''}}</p>
    <div class="checkboxes">
        <mat-checkbox *ngFor="let item of field.choices | keyvalue" [checked]="shouldBeSelected(item.key)"
            [class.selected]="shouldBeSelected(item.key)" (change)="itemChanged(item.key, $event.checked)"
            color="primary">
            <span class="text">{{ item.value.value || item.value }}</span>
        </mat-checkbox>
    </div>
</div>