<table>
    <tbody>
        <td *ngFor="let buttonKey of objectKeys(table.buttons)">
            <p class="view_button" *ngIf="table.buttons[buttonKey].Label">
                <input style="margin-right:4px"
                    type="button" 
                    [value]="table.buttons[buttonKey].Label" 
                    class="btn btn-success" (click)="handleButtonClick(table.buttons[buttonKey])"/>
            </p>
        </td>
    </tbody>
</table>

<div *ngIf="this.dataSource.dataLength >= 25" id="div_search">
    <p>
        <a id="tb_search" (click)="toggleSearch()"><span id="text_search">{{this.showSearch ? "Hide Search" :
                "Search"}}</span></a>
        <span id="text_search_span" style="display:none">Search</span>
    </p>
</div>

<table *ngIf="this.showSearch" class="tbSeachField" style="margin-bottom: 10px;" cellspacing="1">
    <p class="tbSeachField">Enter the term(s) you would like to search for.
        <tbody>
            <tr *ngFor="let column of table.columns; let i=index">
                <ng-container *ngIf="column.searchable">
                    <td>{{column.header}}</td>
                    <td><input type="text" class="search_field" (keyup)="filter($event, i)"></td>
                </ng-container>
            </tr>
            <tr>
            <td valign="bottom" height="40px" style="padding-bottom:0">
                <input type="button" id="SeachTB" (click)="toggleSearch()" value="Submit" class="btn btn-success">
            </td>
            </tr>
        </tbody>
</table>

<div *ngIf="paginatorInfo != null && (true || this.dataSource.dataLength > 25)">
    <ul class="pagination">
        <li [class.disabled]="!paginatorInfo.canGoBack()"><a (click)="paginatorInfo.goBackAllPages()">&lt;&lt;</a>
        </li>
        <li [class.disabled]="!paginatorInfo.canGoBack()"><a (click)="paginatorInfo.goBackOnePage()">&lt;</a></li>
        <li>
            <select #paginatorPageSize name="lstItemView" id="item_view" (change)="paginatorInfo.setCount(paginatorPageSize.value)">
                <option value="25" [selected]="paginatorInfo.pageSize == 25">25</option>
                <option value="50" [selected]="paginatorInfo.pageSize == 50">50</option>
                <option value="100" [selected]="paginatorInfo.pageSize == 100">100</option>
                <option value="500" [selected]="paginatorInfo.pageSize == 500">500</option>
                <option value="1000" [selected]="paginatorInfo.pageSize == 1000">1000</option>
            </select>
        </li>
        <li [class.disabled]="!paginatorInfo.canGoForward()"><a (click)="paginatorInfo.goForwardOnePage()">&gt;</a>
        </li>
        <li [class.disabled]="!paginatorInfo.canGoForward()"><a (click)="paginatorInfo.goForwardAllPages()">&gt;&gt;</a>
        </li>
    </ul>
</div>

<div id="table_css" class="table1" style="position:relative; margin-right: 5px;">
    <span class="export_button" style="display: inline-block;float:right;position:absolute;z-index:999;top:-47px;right:0;">

        <a href="#"
            class="btn btn-success btn-export-pdf " target="_blank"
            style="padding: 0;box-shadow: 0px 1px 0px 0px #670808;">
            <img [src]="'../../../assets/easysuite/' + appID + '/pdf.png'" style="border-radius: 4px;">

        </a>
        <span style="margin-right: 5px;"></span>

    <a href="#" class="btn btn-success btn-export-txt " target="_blank" style="padding: 0;box-shadow: 0px 1px 0px 0px #670808;">
        <img [src]="'../../../assets/easysuite/'+ appID +'/txt.png'" style="border-radius: 4px;">
    </a>
    <span style="margin-right: 5px;"></span>

    <a href="#" class="btn btn-success btn-export-xls " target="_blank" style="padding: 0;box-shadow: 0px 1px 0px 0px #670808;">
        <img [src]="'../../../assets/easysuite/'+ appID +'/xls.png'" style="border-radius: 4px;"></a>
    </span>

    <div id="table-scroll" class="table-scroll">
        <div class="table-wrap">

            <table id="rt1" class="rt cf main-table">
                <tbody>
                    <tr>
                        <th title="" style="vertical-align:top; text-align:center" *ngFor="let column of table.columns; let i=index">{{column.header}}
                            <span *ngIf="column.sortable === '1'" style="display:inline-block" class="triangle_sort up_sort"></span>
                            <a *ngIf="column.headerTooltip != ''" class="infolink sortlink" title="View Report Filters &amp; Columns">?</a>
                        </th>                     
                    </tr>

                    <tr *ngFor="let row of this.dataSource.connect() | async;">
                        <td style="vertical-align:top; text-align:left" *ngFor="let col of row; let i=index">
                            <span *ngIf="this.table.columns[i].action != 1 && this.table.columns[i].action != 2 && this.table.columns[i].bool.toString() != '1' && this.table.columns[i].actionText != 'View'">
                                <span class="ng-binding" *ngIf="!hasLink(col) && !hasCheckbox(col)">{{col}}</span>
                            </span>
                            <span *ngIf="this.table.columns[i].bool.toString() == '1'">
                                <span class="ng-binding" *ngIf="col != ''">Yes</span>
                                <span class="ng-binding" *ngIf="col === ''">No</span>
                            </span>
                            <span *ngIf="this.table.columns[i].action.toString() === '1'">
                                <span class="ng-binding" *ngIf="!hasLink(col) && !hasCheckbox(col)"><img
                                        src="../../../../assets/easysuite/b_edit.png" alt=""
                                        style="margin-right: auto; margin-left: auto; display: block;"></span>
                            </span>
                            <span *ngIf="this.table.columns[i].action.toString() === '2'">
                                <span class="ng-binding" *ngIf="!hasLink(col) && !hasCheckbox(col)"><img
                                        src="../../../../assets/easysuite/b_drop.png" alt=""
                                        style="margin-right: auto; margin-left: auto; display: block;"></span>
                            </span>                       
                            <ng-container *ngIf="hasCallbackLink(row[i])">
                                <a (click)="handleCallbackLink(buildCallbackLink(row[i]))">{{buildCallbackLink(row[i]).linkText}}</a>
                            </ng-container>
                            <span *ngIf="this.table.columns[i].actionText == 'View'">
                                <a (click)="handleViewLink(buildViewLink(row[i]))">View</a>
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="this.dataSource.dataLength == 0">
                        <td>
                            <span class="noData"><strong>No results found</strong></span>                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="paginatorInfo != null && (true || this.dataSource.dataLength > 25)">
        <ul class="pagination">
            <li [class.disabled]="!paginatorInfo.canGoBack()"><a (click)="paginatorInfo.goBackAllPages()">&lt;&lt;</a>
            </li>
            <li [class.disabled]="!paginatorInfo.canGoBack()"><a (click)="paginatorInfo.goBackOnePage()">&lt;</a></li>
            <li>
                <select #paginatorPageSize name="lstItemView" id="item_view" (change)="paginatorInfo.setCount(paginatorPageSize.value)">
                    <option value="25" [selected]="paginatorInfo.pageSize == 25">25</option>
                    <option value="50" [selected]="paginatorInfo.pageSize == 50">50</option>
                    <option value="100" [selected]="paginatorInfo.pageSize == 100">100</option>
                    <option value="500" [selected]="paginatorInfo.pageSize == 500">500</option>
                    <option value="1000" [selected]="paginatorInfo.pageSize == 1000">1000</option>
                </select>
            </li>
            <li [class.disabled]="!paginatorInfo.canGoForward()"><a (click)="paginatorInfo.goForwardOnePage()">&gt;</a>
            </li>
            <li [class.disabled]="!paginatorInfo.canGoForward()"><a (click)="paginatorInfo.goForwardAllPages()">&gt;&gt;</a></li>
        </ul>
    </div>
</div>