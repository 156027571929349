import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form } from 'src/app/base/interfaces/form';
import { UntypedFormControl, UntypedFormGroup, FormsModule, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PageActionResponse } from 'src/app/base/interfaces/pageActionResponse';
import { MatDialog } from '@angular/material/dialog';
import { InvalidPopupComponent } from '../invalid-popup/invalid-popup.component';
import { ContentService } from 'src/app/base/services/content/content.service';
import { FieldIDsThatRequireAnotherField } from 'src/app/constants';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})

export class FormComponent implements OnInit {

    @Input() form: Form;
    @Input() showSubmitButton: boolean = true;
    @Input() parentSubmitButton: Observable<boolean> = null;
    @Input() pageID: number = null;

    @Output() formSubmitted = new EventEmitter<Record<number, string>>();
    @Output() pageAction: EventEmitter<PageActionResponse> = new EventEmitter<PageActionResponse>();
    navID;
    formGroup: UntypedFormGroup;
    inputValue: string;
    buttons: any [] = [];

    constructor(private route: ActivatedRoute, private dialog: MatDialog, private contentService: ContentService) { }

    ngOnInit() {
        this.navID = this.route.snapshot.data.nav;
        let endDate: any;
        if (this.parentSubmitButton != null) {
            this.parentSubmitButton.subscribe(x => {
                this.submitForm();
            })
        }

        this.formGroup = new UntypedFormGroup({}, {
            updateOn: 'change'
        });

        // key is the field ID
        for (let key in this.form.fields) {
            let field = this.form.fields[key];

            let newControl = new UntypedFormControl();

            if (field.required) newControl.addValidators(Validators.required);
            if (field.value) newControl.setValue(field.value);

            // Check if the current field requires another field
            const CurrentFieldRequiresAnotherField = FieldIDsThatRequireAnotherField.find(field => field.key == key);

            // If this field requires another field, add a validator to check if the other field is filled out during the ON SUBMIT
            if (CurrentFieldRequiresAnotherField) {
                this.formGroup.addValidators(this.FieldARequiresFieldBValidator(key, CurrentFieldRequiresAnotherField.value));
            }

            this.formGroup.addControl(key, newControl);
        }

        this.buttons = Object.values(this.form.form_buttons);

        if (this.formGroup.controls['58'] != undefined) {
            this.formGroup.controls['58'].valueChanges.subscribe(x => this.promptEndDate())
        }

        if (this.formGroup.controls['58'] != undefined) {
            this.formGroup.controls['58'].valueChanges.subscribe(x => this.promptEndDate())
        }
    }

    // Only for additional buttons, not submit or cancel button
    additionalFormButtonClick(button){
        if (button.Type === "callback") {
            // We call the contentService service to process the button action
            this.contentService.submitButtonClick(this.navID, this.pageID, button.Label, button.Options).subscribe({
                next: (x) => {
                    /* 
                    * When we are going to make a 'redirect' to the same page we cannot handle it with the Angular router, 
                    * since it only reloads the component, but does not make the request to the Backend again, 
                    * so we must do it with a complete reload
                    */
                    if (x.action === "redirect" && x.pageID == this.pageID) {
                        window.location.reload();
                    } else {
                        this.pageAction.emit(x);
                    }
                },
                error: (err) => console.log(err)
            });
        } else if(button.Type == "link"){
        }
    }

    submitForm() {
        if (!this.formGroup.valid) {
            let list = []
            for (let key in this.form.fields) {
                if (this.formGroup.get(key).invalid) {
                    this.formGroup.get(key).markAsTouched();
                }
                list.push(key)
            }

            if (this.formGroup.invalid) {

                // This variable will hold custom errors (Currently only can be error for FieldARequiresFieldBValidator)
                const errors = this.formGroup.errors;

                // If the errors exists
                if (errors) {

                    // Popop the custom error
                    const popupDialog = this.dialog.open(InvalidPopupComponent, {
                        data: {
                            message: Object.values(errors)[0]
                        }
                    });
                } 
                // Else the only other error is if the user submitted the form without filling out all required fields
                else {
                    const popupDialog = this.dialog.open(InvalidPopupComponent, {
                        data: {
                            message: "All highlighted fields are required in their proper format."
                        }
                    });
                }
            }
            return
        }

        //form is valid, submit it
        let fieldsToSubmit: Record<number, string> = {};
        for (let key in this.form.fields) {

            // If the field has a value
            if (this.formGroup.get(key).value) {
                fieldsToSubmit[key] = this.formGroup.get(key).value;
            }
        }

        this.formSubmitted.emit(fieldsToSubmit);
    }

    cancelForm() {
        //go to previous page
        window.history.back();
    }

    fieldValidate(field, value: string): void {
        // The phone number comes from the backend as "phone number", so I'm doing a replace of the empty spaces so that it matches the keys of the formatPatterns object
        const type = field.value.format
        const normalizedFieldType = type.replace(/\s+/g, '_');

        // These are the regular expressions that each format will allow
        const formatPatterns: { [key: string]: RegExp } = {
          number: /^[0-9]*$/,
          phone_number: /^\(\d{3}\)-\d{3}-\d{4}$/, 
          price: /^[0-9]*\.?[0-9]{0,2}$/
        };

        // Error Message for each format
        const errorMessages: { [key: string]: string } = {
            number: 'The field must contain only numbers.',
            phone_number: 'The phone number must be in the format (XXX)-XXX-XXXX.',
            price: 'The field must contain only numbers.'
        };
    
    
        const pattern = formatPatterns[normalizedFieldType];
        if (pattern && !pattern.test(value)) {
            const popupDialog = this.dialog.open(InvalidPopupComponent, {
                data: {
                    message: `Invalid format: ${errorMessages[normalizedFieldType]}`
                }
            });

            // Reset the input value and update the corresponding FormControl in the FormGroup
            this.inputValue = '';
            this.formGroup.get(field.key)?.setValue(this.inputValue);

            // The popup will close automatically after 3 seconds
            setTimeout(() => {
                popupDialog.close();
            }, 3000);
        }
         
    }

    asIsOrder(a, b) {
        return a.value.fieldOrder > b.value.fieldOrder ? 1 : -1;
    }

    promptEndDate(): any {
        if (this.form.fields['58'] && this.form.fields['59']) {
            if (this.form.fields['58'] != undefined) {
                return this.formGroup.controls['59'].setValue(this.formGroup.controls['58'].getRawValue());
            }
        }
    }

    firstLabel(): void {
        for (let key in this.form.fields) {
            if (this.formGroup.get(key).markAsTouched) {
                let label = this.form.fields[key].label
            }
        }
    }

    // Field Validation functions //

    /*
    This field validation function will return a function that will check if FieldA has a value and FieldB does not, if that is the case then it will return an error.
    */
    FieldARequiresFieldBValidator(FieldA_ID, FieldB_ID): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {

            const FieldA = formGroup.get(FieldA_ID);
            const FieldB = formGroup.get(FieldB_ID);

            // If fieldA has a value and fieldB does not, return an error
            if (FieldA.value && !FieldB.value) {

                // Get the labels of the fields to use in the error message
                const FieldA_Label = this.form.fields[FieldA_ID].label;
                const FieldB_Label = this.form.fields[FieldB_ID].label;

                return { FieldARequiresFieldBError: `${FieldB_Label} still needs a value because ${FieldA_Label} has a value.`};
            }
            return null;
        };
    }

}
