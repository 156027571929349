<table border="0" class="main table_form" cellspacing="0" cellpadding="0">
    <tbody>
        <ng-container *ngFor="let field of this.form.fields | keyvalue: asIsOrder">
            <tr app-easysuite-radio *ngIf="field.value.type === 'radio'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></tr>
            <tr app-easysuite-timepicker *ngIf="field.value.type === 'time picker'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></tr>
            <tr app-easysuite-datepicker *ngIf="field.value.type === 'date picker'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></tr>
            <tr app-easysuite-textfield *ngIf="field.value.type === 'text'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></tr>
            <app-easysuite-multilevel-checkbox *ngIf="field.value.type === 'multilevel-checkbox'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></app-easysuite-multilevel-checkbox>
            <tr app-easysuite-multi-checkbox *ngIf="field.value.type == 'multi-checkbox'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></tr>
            <tr app-easysuite-textarea *ngIf="field.value.type === 'textarea'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></tr>
            <tr app-easysuite-dropdown *ngIf="field.value.type === 'dropdown'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></tr>
            <app-easysuite-text-dollars *ngIf="field.value.type === 'text dollars'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></app-easysuite-text-dollars>
            <tr app-easysuite-checkbox *ngIf="field.value.type === 'checkbox'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></tr>
            <tr app-easysuite-upload *ngIf="field.value.type === 'file'" [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></tr>
            <app-easysuite-email-confirmation *ngIf="field.value.type === 'Email with confirmation'"
                [field]="field.value" [fieldKey]="field.key"
                [formGroup]="this.formGroup"></app-easysuite-email-confirmation>
            <tr app-easysuite-password-confirmation *ngIf="field.value.type === 'Password with confirmation'"
                [field]="field.value" [fieldKey]="field.key" [formGroup]="this.formGroup"></tr>
            <tr app-easysuite-password *ngIf="field.value.type === 'password'" [field]="field.value"
                [fieldKey]="field.key" [formGroup]="this.formGroup"></tr>
            <!-- todo: investigate what's going on with this one -->
            <!-- <app-easysuite-multilevel-checkbox-noselect *ngIf="field.value.type === 'multilevel-checkbox-noselect'" [field]="field.value" [fieldKey]="field.key" [formGroup]="this.formGroup"></app-easysuite-multilevel-checkbox-noselect> -->
        </ng-container>
    </tbody>
</table>

<p style="margin-top:10px;margin-bottom:9px;">
    <input *ngIf="this.form.showSubmitButton" id="btn_submit" type="button" (click)="submitForm()"
        class="btn btn-success" value="Submit" style="margin-right:5px;">
    <input *ngIf="this.form.showCancelButton" id="btn_cancel" type="button" (click)="cancelForm()"
        class="btn btn-success" value="Cancel">
</p>