<ng-container>
    <ng-template #subItem let-item>
        <li [class.has-sub]="item.children && item.children.length > 0" [class.active]="isActive(item.link)" style="cursor: grab;">
          <a (click)="navigate(item.link)">{{ item.label }}</a>
      
          <!-- Recursive check for child navigation -->
          <ul *ngIf="item.children && item.children.length > 0" class="nav_menu nav_menu_sub">
            <ng-container *ngFor="let subChild of item.children">
              <ng-container *ngTemplateOutlet="subItem; context: { $implicit: subChild }"></ng-container>
            </ng-container>
          </ul>
        </li>
      </ng-template>
      

    <ng-template #rootItem let-item>
        <li class="level0 has-sub" [class.active]="isActive(item.path)" style="cursor: grab;">
            <a (click)="navigate(item.path)">{{item.data.label | uppercase}}</a>
            <ul *ngIf="item.childNavs && item.childNavs.length > 0" class="nav_menu nav_menu_full">
                <ng-container *ngFor="let child of item.childNavs">
                    <ng-container *ngTemplateOutlet="subItem; context: {$implicit: child}"></ng-container>
                </ng-container>
            </ul>
        </li>
    </ng-template>

    <div id="menu">
        <nav class="nav" id="multimenu">
            <ul class="nav_menu nav_menu_full nav_menu_root" style="display: none;">

                <ng-container>

                    <ng-container *ngFor="let item of data">
                        <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: item}"></ng-container>
                    </ng-container>

                    <li *ngIf="this.isRegMode" class="level0 has-sub">
                        <a style="cursor: pointer;" [class.active]="isActive">{{this.regRole | uppercase}}</a>
                    </li>

                    <li class="level0">
                        <a (click)="logout()">{{this.isRegMode ? "Back to Login" : "LOGOUT"}}</a>
                    </li>
                </ng-container>
            </ul>
        </nav>
    </div>
</ng-container>
