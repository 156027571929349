import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from '../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(private http: HttpClient, public auth: AuthService) {}

    /**
     * Fetches the routes from the V2.
     *
     * This function makes an HTTP GET request to the specified endpoint to retrieve the
     * routes available for the currently authenticated user based on their role. The
     * retrieved data is returned as a Promise.
     * @returns {Promise<any>} A Promise containing the routes.
     *
     */
    async getRoutes(): Promise<any> {
        try {
            const routeName = await this.http.get<any>(environment.urls.core + "/auth/routes").toPromise();
            return routeName;
        } catch (error) {
            console.error('Error fetching routes:', error);
            throw error;
        }
    }
}