
<ng-container [formGroup]="this.formGroup">
    <!-- First password field -->
    <td valign="middle" class="label_field_4" style="font-weight: 500;">
        {{this.field.label}}
        <span style="color:red" *ngIf="this.field.required">*</span>
        <a *ngIf="this.field.instructions" class="intructionlink" title="{{this.field.instructions}}">?</a>:
    </td>
    <td valign="middle">
        <input class="field_submit" type="password" placeholder="{{this.field.label}}" 
            [formControlName]="this.fieldKey" />
    </td>
    
    <!-- Confirm password field -->
    <td valign="middle" class="label_field_4" style="font-weight: 500;">
        Confirm {{this.field.label}}
        <span style="color:red" *ngIf="this.field.required">*</span>
        <a *ngIf="this.field.instructions" class="intructionlink" title="{{this.field.instructions}}">?</a>:
    </td>
    <td valign="middle">
        <input class="field_submit" type="password" placeholder="Confirm {{this.field.label}}" 
            [formControlName]="this.fieldKey" />
    </td>
</ng-container>
