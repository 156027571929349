<ng-container [formGroup]="this.formGroup" class="main"
    [class.invalid]="!this.formGroup.controls[this.fieldKey].valid && this.formGroup.controls[this.fieldKey].touched">
    <td valign="middle" style="font-weight: 500;">
        {{field.label}}<span style="color:red">*</span>:
    </td>
    <td valign="middle">
        <div class="checkbox-container">
            <mat-checkbox *ngFor="let item of field.choices | keyvalue"
                [checked]="shouldBeSelected(item.key)"
                [class.selected]='shouldBeSelected(item)' 
                class="control control--checkbox" style="font-weight:normal"
                (change)="itemChanged(item.key, $event.checked)">
                <span *ngIf="item.value.value" class="text">{{item.value.value}}</span>
                <span *ngIf="!item.value.value" class="text">{{item.value}}</span>
            </mat-checkbox>
        </div>
    </td>
</ng-container>