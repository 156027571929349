import { Component, OnInit } from '@angular/core';
import { TimePickerBaseComponent } from 'src/app/base/components/fields/timepicker-base.component';

@Component({
    selector: '[app-easysuite-timepicker]',
    templateUrl: './easysuite-timepicker.component.html',
    styleUrls: ['./easysuite-timepicker.component.css']
})
export class TimepickerComponent extends TimePickerBaseComponent implements OnInit {

    constructor() { super() }

    ngOnInit(): void {
        if (this.field.value) {
            const timeValue = this.timeFormatConvertion(this.field.value);
            this.formGroup.get(this.fieldKey)?.setValue(timeValue);
        }
    }
    onTimeChange(event: any): void {
        const value = event.target.value;
        this.formGroup.get(this.fieldKey)?.setValue(value);
    }

    timeFormatConvertion(value: string): string {
        if (!value) return '';
        const [time, modifier] = value.split(/(AM|PM)/i);
        let [hours, minutes] = time.trim().split(':').map(Number);

        if (modifier.toLowerCase() === 'pm' && hours < 12) {
            hours += 12;
        }
        if (modifier.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

}
