import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { SuperstructureBaseComponent } from 'src/app/base/components/superstructure-base.component';
import { AppidService } from 'src/app/base/services/appid/appid.service';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { ContentService } from 'src/app/base/services/content/content.service';
import { I3VService } from 'src/app/base/services/i3v/i3v.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { WorkflowService } from 'src/app/base/services/workflow/workflow.service';
import { Location } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { ThemeService } from 'src/app/base/services/theme/EZScssTheme.service';
import { ButtonService } from 'src/app/base/services/buttons/buttons.service';


@Component({
    selector: 'app-easysuite-superstructure',
    templateUrl: './easysuite-superstructure.component.html',
    styleUrls: ['./easysuite-superstructure.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class SuperstructureEasysuiteComponent extends SuperstructureBaseComponent implements OnInit {
    @Input() loginPageInfo: PsuedoLoginPageInformation = null;
    @Output("formSubmitted") formSubmittedEvent = new EventEmitter<object>();
    showMobileNav: boolean = true;
    objectKeys = Object.keys;
    constructor(
        public route: ActivatedRoute,
        public content: ContentService,
        public router: Router,
        public sanitizer: DomSanitizer,
        public dialog: MatDialog,
        public location: Location,
        public cdr: ChangeDetectorRef,
        public titleService: Title,
        public auth: AuthService,
        public nav: NavService,
        public i3v: I3VService,
        public workflow: WorkflowService,
        public app: AppComponent,
        public AppId: AppidService,
        public http: HttpClient,
        public theme: ThemeService,
        public button: ButtonService) {
        super(route, content, router, sanitizer, dialog, location, cdr, titleService, auth, nav, i3v, workflow, app, AppId, http, theme, button);
    }
}

/**
 * In EasySuite applications, the login page is supposed to look exactly like a superstructure page.
 * This is fine, but we don't want to waste time copying code from the superstructure into this
 * component (since all that styling is dense, and the code for login is very different).
 * 
 * Instead, we will we will simply "include" the superstructure component, and pass it a declaration
 * of this object, which will contain the information on how to display the page.
 * 
 * Generally, we'll just need the page ID for logging in, but we will also want to include the navs
 * that should be displayed on the page (at this point, the nav service hasn't fetched anything yet)
 */
export class PsuedoLoginPageInformation {
    public InitialPageID: number;
    public LoginNavs: any[] = [];
}

