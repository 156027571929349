<ng-container>
    <td valign="middle" class="label_field_4" style="font-weight: 500;">
        {{ field.label }}
        <span style="color:red" *ngIf="field.required">*</span>
        <a *ngIf="field.instructions" class="intructionlink" title="{{field.instructions}}">?</a>
    </td>

    <td valign="middle">
        <input class="field_submit" type="time" name="field_id_4" maxlength="200"
            [value]="formGroup.get(this.fieldKey)?.value" (change)="onTimeChange($event)" [formControlName]="this.fieldKey" />
    </td>
</ng-container>