import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from '../../../base/services/nav/nav.service';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { navigate } from '../../../base/helpers';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {
    data: Route[] = [];

    @Input("isRegMode") isRegMode: boolean = false;
    @Input("isMobileView") isMobileView: boolean = false;
    @Input("regRole") regRole: string = null;

    showMobileNav = false;
    mobileViewTrigger = this.app.innerWidth < 850;

    constructor(public sideNav: SidenavComponent, public http: HttpClient, public router: Router, public navService: NavService, public authService: AuthService, public activated: ActivatedRoute, public app: AppComponent) { }

    /**
     * Handles navigation to the specified route using a common navigation function.
     *
     * @param link - The target route to navigate to.
     * @returns void
    */
    navigateHandler(link: string): void {
        navigate(this.router, link);
    }

    ngOnInit(): void {

        //get routes from angular router
        this.data = [];

        //filter for all root routes
        this.router.config.filter(route => route.path.split("/").length == 1 && route.data != null).forEach(route => {
            let rootPath = route.path.split("/")[0];

            //recursively find first descendant route where data.hasPage is true
            let firstDescendant = this.router.config.find(r => r.path.split("/")[0] == rootPath && route.path != r.path && r.data != null && r.data.hasPage);
            let index = 1;

            while (firstDescendant != null && firstDescendant.data.hasPage == false) {
                firstDescendant = this.router.config.find(r => {
                    let baseMatch = r.path.split("/")[0] == rootPath && route.path != r.path && r.data != null && r.data.hasPage;
                    return baseMatch && r.path.split("/")[index] == firstDescendant.path.split("/")[index++];
                });
            }

            let routeCopy = { ...route };

            if (firstDescendant == null) {
                console.error("No descendant route found for " + route.path + ", does it have a page attached to it in RADMIN?");
            }

            routeCopy.path = firstDescendant.path;
            this.data.push(routeCopy);
        });

        this.sortNavs();
    }

    sortNavs() {
        this.data.sort((a, b) => {
            if (a.data.sortOrder < b.data.sortOrder) {
                return -1;
            }
            if (a.data.sortOrder > b.data.sortOrder) {
                return 1;
            }
            return 0;
        });
    }


    logout(): void {
        let role = this.authService.getRole();
        this.navService.clearNavs().subscribe(
            () => { // success callback
                this.authService.logout();

                //if role is not set, use regRole
                if (!role) {
                    switch (this.regRole) {
                        case "venue":
                            role = 97;
                            break;
                        case "performer":
                            role = 98;
                            break;
                    }
                }

                this.router.navigateByUrl("/auth/login");
            },
            (error) => {
                console.error("Error clearing the navs during logout:", error);
            }
        );
    }

    //determine if this top nav is active
    isActive(link): boolean {
        //check if this.router.url minus any http parameters matches the link
        let url = this.router.url.split("?")[0];
        link = link.split("/")[0];
        // convert from url from /venueregistration/stages to venueregistration
        url = url.split("/")[1];
        return url == link;
    }

    triggerMobileNav(): void {
        this.showMobileNav = true;
    }

    collapseMobileNav(): void {
        this.showMobileNav = false;
    }

}
