import { Component, OnInit } from '@angular/core';
import { PasswordConfirmationBaseComponent } from 'src/app/base/components/fields/password-confirmation-base.component';

@Component({
    selector: '[app-easysuite-password-confirmation]',
    templateUrl: './easysuite-password-confirmation.component.html',
    styleUrls: ['./easysuite-password-confirmation.component.css']
})
export class EasysuitePasswordConfirmationComponent extends PasswordConfirmationBaseComponent implements OnInit {

    constructor() { super() }

    ngOnInit(): void {
    }

}
