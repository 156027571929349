import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { MobileNavBaseComponent } from 'src/app/base/components/mobile-nav-base.component';
import { TopNavBaseComponent } from 'src/app/base/components/top-nav-base.component';
import { AuthService } from 'src/app/base/services/auth/auth.service';
import { NavService } from 'src/app/base/services/nav/nav.service';
import { SidenavComponent } from 'src/app/gigmonster/components/sidenav/sidenav.component';
import { PsuedoLoginPageInformation } from '../easysuite-superstructure/easysuite-superstructure.component';

@Component({
    selector: 'app-easysuite-mobile-nav',
    templateUrl: './easysuite-mobile-nav.component.html',
    styleUrls: ['./easysuite-mobile-nav.component.css']
})

export class EasysuiteMobileNavComponent extends MobileNavBaseComponent implements OnInit {
    @Input() psuedoLoginInfo: PsuedoLoginPageInformation = null;

    data: any = [];
    showMobileNav: boolean = false;

    constructor(public sideNav: SidenavComponent,
        public http: HttpClient,
        public router: Router,
        public navService: NavService,
        public authService: AuthService,
        public activated: ActivatedRoute,
        public app: AppComponent,
        public activatedRoute: ActivatedRoute) {
        super(router, navService, authService);
    }

    /**
     * overrides ngonicit in topnavbase, used because we care about descendants as well
     */
    ngOnInit(): void {
        this.data = [];

        //find all root routes
        let filteredRoutes = this.router.config.filter(x => x.path.split("/").length == 1 && x.path != "auth" && x.path != "**");

        filteredRoutes.forEach(x => {
            this.data.push(x);
        });

        this.data = this.data.sort(this.asIsOrder);

        //for each root route, find all descendants
        this.data.forEach(route => {
            route.childNavs = this.buildNavArray(this.router.config, route.path, 0);
        });

        this.data.forEach(item => {
            if (item.childNavs) {
                item.childNavs.forEach(childNavs => {
                    childNavs.collapsed = !childNavs.collapsed;
                });
            }
        });

    }

    buildNavArray(routes: Route[], basepath: string, level: number): any {
        let newItems = [];

        let thisLevelRoutes = routes.filter(x => {
            let validPath = true;
            if (basepath != "") {
                validPath = x.path.indexOf(basepath) == 0;
            }
            return x.path.split("/").length == level + 2 && validPath
        });

        thisLevelRoutes.forEach(item => {
            let itemChildren = this.buildNavArray(routes, item.path + "/", level + 1);
            if(itemChildren.length > 0){
                itemChildren.sort(this.asIsOrder);
            }

            let link = "/" + item.path;
            let originalLink = link;
            if (!item.data.hasPage && itemChildren.length > 0 && itemChildren[0].hasPage) {
                link = itemChildren[0].link;
            }

            let newItem = {
                label: item.data.label,
                link: link,
                children: itemChildren,
                hasPage: item.data.hasPage,
                collapsed: true,
                originalLink: originalLink,
                sortOrder: item.data.sortOrder,
            };

            newItems.push(newItem);
        });

        return newItems;
    }

    isActive(link): boolean {
        return this.router.url.split("/")[1] == link.split("/")[0];
    }

    isSideNavActive(link): boolean {
        //checks if active class is only applied if the current URL starts with the specified link
        return this.router.url.startsWith(link);
    }
    
    asIsOrder(a, b) {
        if(a.data?.sortOrder && b.data?.sortOrder){
            return Number.parseInt(a.data.sortOrder) > Number.parseInt(b.data.sortOrder) ? 1 : -1;
        } else {
            return Number.parseInt(a.sortOrder) > Number.parseInt(b.sortOrder) ? 1 : -1;
        }
    }

    triggerMobileNav(): void {
        this.showMobileNav = true;
    }

    collapseMobileNav(): void {
        if (this.showMobileNav == false) {
            this.showMobileNav = true;
        }
        else {
            this.showMobileNav = false;
        }
    }
}