<ng-container>
    <td valign="middle" class="label_field_906" style="font-weight: 500;">{{field.label}}:
    <span style="color:red" *ngIf="this.field.required">*</span></td>
    <td valign="middle">
        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload style="font-weight: 500;">
        <!--button mat-raised-button (click)="fileUpload.click()" class="upload-btn"
            style="font-weight: 500;">{{this.field.label}}</button-->
        <p style="font-weight: 500;">{{this.fileName}}</p>
        <a *ngIf="this.uploadedFile != null" target="_blank" [href]="this.uploadedFile">Uploaded file</a>
    </td>
    
</ng-container>