// This file is used to determine which css to use based on the app that is being rendered
import { Injectable, Directive, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    private renderer: Renderer2;
    constructor(
      @Inject(DOCUMENT) private document: Document,
      rendererFactory: RendererFactory2) {
      this.renderer = rendererFactory.createRenderer(null, null);
    }
  
    loadTheme(appId: number) {
      // Find the theme configuration based on the appId
    const themeConfig = { href: '/assets/easysuite/'+ appId +'/'+ appId +'.css', themeName: String(appId) }
  
      if (!themeConfig) {
        console.warn(`No theme found for appId ${appId}`);
        return;
      }
  
      // Remove existing theme link, if any
      const existingLink = this.document.querySelector('link[data-theme]');
      if (existingLink) {
        this.renderer.removeChild(this.document.head, existingLink);
      }
  
      // Create and append the new theme link
      const link = this.renderer.createElement('link') as HTMLLinkElement;
      this.renderer.setAttribute(link, 'rel', 'stylesheet');
      this.renderer.setProperty(link, 'href', themeConfig.href);
      this.renderer.setAttribute(link, 'data-theme', themeConfig.themeName);
      this.renderer.appendChild(this.document.head, link);
    }
  }