<ng-template #rootItem let-item>
    <li class="has-sub show_parent" [class.active]="isActive(item.link)" style="cursor: grab;">
        <a (click)="navigate(item.link)">{{item.label | uppercase}}</a>
        
        <span class="side_nav_submenu" *ngIf="hasChildren(item)" (click)="expandOrCollapse(item)" [class.side_nav_open]="item.collapsed == true"></span>

        <!-- Add ul for the children items if they exist and are not collapsed -->
        <ul *ngIf="item.collapsed == false">
            <ng-container *ngFor="let itemChild of item.children">
                <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: itemChild}"></ng-container>
            </ng-container>
        </ul>
    </li>
</ng-template>

<div class="sidebarmenu">
    <ul class="sidebar_menu">

        <ng-container>
            <ng-container *ngFor="let item of data">
                <ng-container *ngTemplateOutlet="rootItem; context: {$implicit: item}"></ng-container>
            </ng-container>

            <!-- registration role logic -->
            <ng-container *ngIf="this.isRegMode">
                <li>
                    <a class="" [class.selected]="isActive">REGISTRATION</a>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>
